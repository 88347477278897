.btn, .btn:active, .btn:hover, .btn:focus {
  outline: none;
}

.btn:not(.pull-right) + .btn:not(.pull-right) {
  margin-left: 15px;
}

.btn.pull-right + .btn.pull-right {
  margin-right: 15px;
}

.bordered {
  border: 1px solid #dedede
}

.btn.btn-flat:focus {
  color: @text-color;
}

.btn-flat:hover:after,
.btn-flat:active:after,
.btn-flat:focus:after {
  content: '';
  width: 100%;
  position: absolute;
  border-top: 3px solid transparent;
  bottom: 0px;
  left: 0;
  margin-left: 0;
}

.btn-flat.waves-primary:after {
  border-top-color: @brand-primary;
}

.btn-flat.waves-success:after {
  border-top-color: @brand-success;
}

.btn-flat.waves-info:after {
  border-top-color: @brand-info;
}

.btn-flat.waves-danger:after {
  border-top-color: @brand-danger;
}

.btn-flat.waves-orange:not(.btn-warning):after,
.btn-flat.waves-warning:not(.btn-warning):after {
  border-top-color: @brand-warning;
}

.btn:not(.pull-right) + .btn:not(.pull-right) {
  margin-left: 15px;
}

.btn-danger {
  background: @brand-danger;
  color: white;

  &:hover, &:focus {
    background: @brand-danger-hover;
    color: @btn-default-color;
  }
}

.btn-success {
  background: @brand-success;

  &:hover, &:focus {
    background: @brand-success-hover;
    color: @btn-default-color;
  }
}

.btn-primary {
  background: @brand-primary;

  &:hover, &:focus {
    background: @brand-primary-hover;
    color: @btn-default-color;
  }
}

.btn-info {
  background: @brand-info;

  &:hover, &:focus {
    background: @brand-info-hover;
    color: @btn-default-color;
  }
}

.btn-warning {
  background: @brand-warning;
  color: white;

  &:hover, &:focus {
    background: @brand-warning-hover;
    color: @btn-default-color;
  }
}

//
//.btn-lg {
//  padding: 10px 30px;
//}
//
//.btn-sm {
//  padding: 5px 18px;
//}
//
//.btn-xs {
//  padding: 1px 12px;
//}
//
//.btn.btn-outline {
//  &.btn-default,
//  &.btn-danger,
//  &.btn-info,
//  &.btn-success,
//  &.btn-warning,
//  &.btn-primary {
//    background-color: #fff;
//  }
//
//  &:hover {
//    &.btn-default {
//      background-color: @gray-lighter;
//    }
//
//    &.btn-danger {
//      background-color: @brand-danger-hover;
//    }
//
//    &.btn-info {
//      background-color: @brand-info-hover;
//    }
//
//    &.btn-success {
//      background-color: @brand-success-hover;
//    }
//
//    &.btn-warning {
//      background-color: @brand-warning-hover;
//    }
//
//    &.btn-primary {
//      background-color: @brand-primary-hover;
//    }
//  }
//}