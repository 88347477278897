.board {
  position: relative;
  height: 100%;

  .board-search {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  .board-title {
    margin: 0;
    font-size: 22px;
  }

  .board-description {
    padding-left: 7px;
    line-height: 31px;
    font-size: 100%;
    margin: 0;
  }

  .board-heading {
    position: absolute;
    top: 70px;
    right: 0;
    left: 0;
    width: 100%;
    padding: 0 @grid-gutter-width / 2;
  }

  .board-wrapper {
    height: 100%;
    padding-top: 116px;
  }

  .board-body {
    margin-left: -@grid-gutter-width / 2;
    margin-right: -@grid-gutter-width / 2;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    height: 100%;
    position: relative;
    padding-bottom: @grid-gutter-width / 2;

    .board-column {
      width: 320px;
      padding: 0 @grid-gutter-width / 2;
      display: inline-block;
      vertical-align: top;
      height: 100%;

      .complete {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #e7e7e7;
        border: 1px solid #ddd;

        &.hover {
          background-color: #ddd;
          border: 1px solid #ccc;
        }

        &:after{
          content: 'Drop Here to Complete';
          font-size: 20px;
          color: #626262;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
  }
}

.avatar-list {
  list-style: none;
  padding: 0;

  li {
    padding: 5px;
    display: inline-block;

    .avatar {
      cursor: pointer;
    }
  }
}