.card {
  border: 1px solid transparent;
  padding: @grid-gutter-width / 3 @grid-gutter-width / 2;
  cursor: pointer;
  transition: transform 0.3s;
  transform: rotate(0deg);

  &.ui-sortable-helper {
    transform: rotate(5deg);
    cursor: move;
    cursor: -webkit-grab;
  }

  .due-date {
    display: block;
  }

  .card-footer {
    padding-top: 6px;

    small, p, img, a, span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .card-avatar {
    background-color: @card-avatar-bg;
    height: @card-avatar-height;
    width: @card-avatar-width;
    display: inline-block;
    text-align: center;
    border-radius: @card-avatar-width / 2;
    overflow: hidden;
    vertical-align: middle;
  }

  &.card-low {
    background-color: @card-low-bg;
    border-color: @card-low-border;
    color: @card-low-color;

    &:hover {
      background-color: darken(#fff, 3%);
    }
  }
  &.card-moderate {
    background-color: @card-moderate-bg;
    border-color: @card-moderate-border;
    color: @card-moderate-color;

    &:hover {
      background-color: @brand-primary-hover;
    }
  }
  &.card-important {
    background-color: @card-important-bg;
    border-color: @card-important-border;
    color: @card-important-color;

    &:hover {
      background-color: @brand-warning-hover;
    }
  }
  &.card-critical {
    background-color: @card-urgent-bg;
    border-color: @card-urgent-border;
    color: @card-urgent-color;

    &:hover {
      background-color: @brand-danger-hover;
    }
  }
}

.card-modal {
  .modal-header {
    border-color: transparent;
    padding-bottom: 0;
  }

  .add-description {
    padding: 6px 12px;
    border-bottom: 1px solid #ccc;
    color: #a1a1a1;
    display: block;
  }

  .datepicker {
    width: 100%;

    .datepicker-input {
      width: 100% ! important;
    }
  }

  textarea.form-control,
  input[type="text"].form-control,
  input[type="search"].form-control,
  input[type="email"].form-control,
  .datepicker-input {
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    box-shadow: none;
    resize: none;

    &:focus {
      border-left-color: #ccc;
      border-right-color: #ccc;
      border-top-color: #ccc;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      background-color: #f3f3f3;
    }
  }
}