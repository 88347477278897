/* iCheck plugin Square skin, blue
----------------------------------- */
.icheckbox_square-blue,
.iradio_square-blue {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(/pages/img/blue.png) no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_square-blue {
  background-position: 0 0;
}
.icheckbox_square-blue.hover {
  background-position: -24px 0;
}
.icheckbox_square-blue.checked {
  background-position: -48px 0;
}
.icheckbox_square-blue.disabled {
  background-position: -72px 0;
  cursor: default;
}
.icheckbox_square-blue.checked.disabled {
  background-position: -96px 0;
}

.iradio_square-blue {
  background-position: -120px 0;
}
.iradio_square-blue.hover {
  background-position: -144px 0;
}
.iradio_square-blue.checked {
  background-position: -168px 0;
}
.iradio_square-blue.disabled {
  background-position: -192px 0;
  cursor: default;
}
.iradio_square-blue.checked.disabled {
  background-position: -216px 0;
}

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .icheckbox_square-blue,
  .iradio_square-blue {
    background-image: url(/pages/img/blue@2x.png);
    -webkit-background-size: 240px 24px;
    background-size: 240px 24px;
  }
}