.login-page {
  height: 100%;
  width: 100%;
  position: relative;
  background: url('/vendor/gml/cms/auth/img/login-bg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-form {
    box-shadow: 0 0 19px -4px rgba(0, 0, 0, 0.4);

    .panel-footer {
      text-align: right;
    }
  }

  .input-group-addon {
    width: 40px;
  }

  .login-heading a {
    color: @text-color;
    text-decoration: none;
  }
}