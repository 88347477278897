.dev-bar {
  background: #B74727;
  color: white;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10000000;
}