.table {
  thead > tr > td,
  tbody > tr > td,
  tfoot > tr > td {
    height: 50px;
    vertical-align: middle;
  }

  tbody > tr:first-child > td {
    border-color: transparent;
  }

  thead > tr > th,
  tbody > tr > th,
  tfoot > tr > th {
    height: 40px;
    vertical-align: middle;
    font-weight: 500;
    border-bottom: 1px solid #ddd;
  }
}