.notification-bar-wrapper {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  display: none;

  .notification-bar {
    position: fixed;
    width: 0;
    right: 0;
    top: 0;
    height: 100%;
    background-color: #fff;
    z-index: 1081;
    // border-left: 1px solid #ddd;
    transition: width 0.4s;

    .notification-bar-content {
      width: 340px;

      .notification-bar-header {
        padding: @grid-gutter-width / 2;
        background-color: @brand-primary;
        color: #fff;

        .close-bar {
          background-color: transparent;
          border: 0;
          float: left;
          padding-top: 4px;
        }

        .notification-bar-title {
          text-align: right;
          margin: 0;
        }
      }
    }
  }

  &.in {
    .notification-bar {
      width: 340px;
    }
  }
}

.notification {
  display: table;
  width: 100%;

  .notification-icon {
    display: table-cell;
    width: 50px;
    padding: 5px 0;
    font-size: 32px;
    vertical-align: middle;
    text-align: center;
  }

  .notification-body {
    display: table-cell;
    padding: 5px @grid-gutter-width / 3;
    vertical-align: middle;
  }

  &.notification-default {
    border-bottom: 1px solid #ddd;

    .notification-icon {
      color: @gray-dark;
    }
  }

  &.notification-primary {
    background-color: @brand-primary;
    color: #fff;
  }

  &.notification-success {
    background-color: @brand-success;
    color: #fff;
  }

  &.notification-info {
    background-color: @brand-info;
    color: #fff;
  }

  &.notification-warning {
    background-color: @brand-warning;
    color: #fff;
  }

  &.notification-danger {
    background-color: @brand-danger;
    color: #fff;
  }
}