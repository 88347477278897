.success {
  background: @brand-success;
}

.danger {
  background: @brand-danger;
  color: white;
}

.primary {
  background: @brand-primary;
}