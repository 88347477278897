.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) button {
  border-radius: 0;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) button {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child):not(.dropdown-toggle) button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.bar-btn-dropdown .dropdown-menu > li > button {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.1;
  color: #ddd;
  white-space: nowrap;
  background-color: #fff;
  -webkit-appearance: none;
  width: 100%;
  border: none;
}

.bar-btn-dropdown .dropdown-menu > li > .checked-dropdown {
  padding-left: 30px;
  position: relative;
  text-align: left;
}

.bar-btn-dropdown .dropdown-menu > li > button .fa-check {
  position: absolute;
  left: 5px;
  top: 50%;
  margin-top: -5px;
  font-size: 14px;
}

.dropdown-menu.inline-opts {
  min-width: 0;
}

.dropdown-menu > li > button:hover,
.dropdown-menu > li > button:focus {
  text-decoration: none;
  color: #888;
  background-color: #ccc;
}

.dropdown-menu > .active > button
.dropdown-menu > .active > button:hover,
.dropdown-menu > .active > button:focus {
  color: #428bca;
  text-decoration: none;
  outline: 0;
  background-color: #fff;
}

.bar-btn-dropdown {
  padding: 0 !important;
  border: none;
}