.panel {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);

  .panel-heading {
    display: table;
    width: 100%;
    color: inherit;
  }

  .panel-title {
    font-size: 24px;
    line-height: 36px;
    float: left;
  }

  .panel-sub-title {
    vertical-align: middle;
    text-align: right;
  }

  .panel-image {
    max-height: 200px;
    overflow: hidden;

    img {
      width: 100%;
      opacity: 0.4;
      transition: all .4s ease-in-out;
    }
  }

  &:hover .panel-image img {
    opacity: 0.7;
    transform: scale(1.1);
  }

  .panel-content {
    display: table;
    width: 100%;
    padding: 10px 0;
  }

  &.panel-card {
    .panel-footer {
      border-top: 1px solid #ddd;

      h1, h2, h3, h4, h5, h6 {
        margin: 10px;
      }
    }
  }

  .panel-list {
    > li {
      margin-bottom: 5px;
    }
  }

  //.panel-btn-row {
  //  display: flex;
  //  bottom: 0;
  //  position: absolute;
  //  width: 100%;
  //  > * {
  //    flex-grow: 1;
  //
  //    & + * {
  //      border-left: 1px solid @btn-separator-color;
  //    }
  //  }
  //}

  .file-card {
    text-align: center;
    height: 210px;
    .panel-content {
    }
    .fa-file-text-o,
    .fa-plus-square-o {
      font-size: 60px;
      margin-bottom: 20px;
    }


    & + .file-card:before {
      content: '';
      border-left: 1px solid #d8d8d8;
      height: 90%;
      position: absolute;
      left: 0;
      top: 6px;
    }
  }

  table tr > *:first-child {
    padding-left: 15px;
  }
  table tr > *:last-child {
    padding-right: 15px;
  }
}