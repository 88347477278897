.project {
  .project-heading {
    padding: @grid-gutter-width / 2;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    display: table;
    width: 100%;

    .project-title {
      margin-top: 0;
    }

    .project-heading-left {
      display: table-cell;
      vertical-align: top;
    }
    .project-heading-right {
      display: table-cell;
      vertical-align: middle;
      width: 300px;
    }

    .project-avatars {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      max-width: 360px;

      .avatar {
        margin: 0 5px;
      }
    }
  }

  .project-body {
    padding: @grid-gutter-width / 2;
  }

  .list, .card {
    margin-bottom: @grid-gutter-width
  }
}