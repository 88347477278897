.widget {
  border-radius: @border-radius-small;
  display: table;
  width: 100%;
  margin-bottom: @grid-gutter-width / 2;

  .widget-icon {
    font-size: 35px;

    &.widget-icon-lg {
      font-size: 60px;
      display: block;
      padding: 20px;
    }
  }

  .widget-left,
  .widget-right {
    display: table-cell;
    width: 100px;
    vertical-align: middle;
  }

  .widget-left {
    border-right: 1px solid transparent;
  }

  .widget-right {
    border-left: 1px solid transparent;
  }

  .widget-heading {
    padding: @grid-gutter-width / 3;
  }

  .widget-body {
    padding: 0 @grid-gutter-width / 3 @grid-gutter-width / 3;
    font-size: 30px;

    .progress {
      background-color: rgba(0,0,0,0.1);
      height: 10px;
      margin: 0;
    }
  }

  .widget-footer {
    padding: 0 @grid-gutter-width / 3 @grid-gutter-width / 3;
    font-size: 12px;
  }

  .widget-title {
    font-size: 16px;
    margin: 0;
  }

  .widget-subtitle {
    display: block;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    margin: 0;
  }

  .widget-data {
    display: table-cell;
    vertical-align: middle;
    font-size: 30px;
    font-weight: 300;
    padding: @grid-gutter-width / 2;

    &.widget-lg {
      font-size: 44px;

      .widget-subtitle {
        font-size: 18px;
      }
    }
  }

  &.widget-default {
    background-color: #fff;
    color: @gray-darker;
    .widget-left,
    .widget-right {
      border-color: darken(#fff, 5%);
    }

    .widget-body .progress .progress-bar {
      background-color: darken(@gray-darker, 15%);
    }
  }

  &.widget-primary {
    background-color: @brand-primary;
    color: #fff;

    .widget-left,
    .widget-right {
      border-color: darken(@brand-primary, 5%);
    }

    .widget-body .progress .progress-bar {
      background-color: darken(@brand-primary, 15%);
    }
  }

  &.widget-success {
    background-color: @brand-success;
    color: #fff;

    .widget-left,
    .widget-right {
      border-color: darken(@brand-success, 5%);
    }

    .widget-body .progress .progress-bar {
      background-color: darken(@brand-success, 15%);
    }
  }

  &.widget-info {
    background-color: @brand-info;
    color: #fff;

    .widget-left,
    .widget-right {
      border-color: darken(@brand-info, 5%);
    }

    .widget-body .progress .progress-bar {
      background-color: darken(@brand-info, 15%);
    }
  }

  &.widget-warning {
    background-color: @brand-warning;
    color: @gray-darker;

    .widget-left,
    .widget-right {
      border-color: darken(@brand-warning, 5%);
    }

    .widget-body .progress .progress-bar {
      background-color: darken(@brand-warning, 25%);
    }
  }

  &.widget-danger {
    background-color: @brand-danger;
    color: #fff;

    .widget-left,
    .widget-right {
      border-color: darken(@brand-danger, 5%);
    }

    .widget-body .progress .progress-bar {
      background-color: darken(@brand-danger, 15%);
    }
  }

  &.widget-gray {
    background-color: @gray-dark;
    color: #fff;

    .widget-left,
    .widget-right {
      border-color: darken(@gray-dark, 5%);
    }

    .widget-body .progress .progress-bar {
      background-color: darken(@gray-dark, 15%);
    }
  }
}

a.widget:hover {
  text-decoration: none;

  &.widget-default {
    background-color: darken(#fff, 10%);
  }

  &.widget-primary {
    background-color: darken(@brand-primary, 10%);
  }

  &.widget-success {
    background-color: darken(@brand-success, 10%);
  }

  &.widget-info {
    background-color: darken(@brand-info, 10%);
  }

  &.widget-warning {
    background-color: darken(@brand-warning, 10%);
  }

  &.widget-danger {
    background-color: darken(@brand-danger, 7%);
  }

  &.widget-gray {
    background-color: darken(@gray-dark, 10%);
  }
}