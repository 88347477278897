.comment {
  position: relative;
  padding: @grid-gutter-width / 3 @grid-gutter-width / 2;
  background-color: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  width: 90%;
  margin-bottom: @grid-gutter-width / 2;

  .comment-footer {
    font-size: 10px;
    color: #626262;
    padding-top: 6px;
  }

  &.comment-default {
    margin-left: 10px;
    background-color: #83C2CD;
    color: #fff;

    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 9px 9px 9px 0;
      border-color: transparent #83C2CD;
      display: block;
      width: 0;
      left: -7px;
      z-index: 2;
      top: 14px;
    }

    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 9px 9px 9px 0px;
      border-color: transparent #CCC;
      display: block;
      width: 0;
      left: -9px;
      top: 14px;
      z-index: 1;
    }
  }

  &.comment-self {
    margin-left: 10%;

    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 9px 0 9px 9px;
      border-color: transparent #fff;
      display: block;
      width: 0;
      right: -7px;
      z-index: 2;
      top: 14px;
    }

    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 9px 0 9px 9px;
      border-color: transparent #CCC;
      display: block;
      width: 0;
      right: -9px;
      top: 14px;
      z-index: 1;
    }
  }
}