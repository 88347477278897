.color-danger {
  color: @brand-danger;
}

.color-primary {
  color: @brand-primary;
}

.color-success {
  color: @brand-success;
}