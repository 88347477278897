text-angular.ta-root {
  button.btn.btn-default {
    color: @text-color;
    background-color: white;
    text-align: center;
    letter-spacing: .5px;
    -webkit-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
    cursor: pointer;
    border: 1px solid #ccc;
    color: @text-color;
    background-color: #ececec;
    padding: 0px 9px;
    margin: 0;
  }

  .btn-default.disabled,
  .btn-default[disabled],
  fieldset[disabled] .btn-default,
  .btn-default.disabled:hover,
  .btn-default[disabled]:hover,
  fieldset[disabled] .btn-default:hover,
  .btn-default.disabled:focus,
  .btn-default[disabled]:focus,
  fieldset[disabled] .btn-default:focus,
  .btn-default.disabled.focus,
  .btn-default[disabled].focus,
  fieldset[disabled] .btn-default.focus,
  .btn-default.disabled:active,
  .btn-default[disabled]:active,
  fieldset[disabled] .btn-default:active,
  .btn-default.disabled.active,
  .btn-default[disabled].active,
  fieldset[disabled] .btn-default.active {
    border: 1px solid #ccc;
  }

  .btn-default:hover,
  .btn-default:focus,
  .btn-default:active,
  .btn-default.active,
  .open .dropdown-toggle.btn-default {
    color: @text-color;
    background-color: #ebebeb;
    border-color: #adadad;
  }

  .btn i,
  .btn-large i,
  .btn-floating i,
  .btn-large i,
  .btn-flat i {
    margin: 0;
    font-size: 13px;
  }

  &.focussed {
    #toolbarWC, #toolbarCC {
      color: #353535 !important;
    }
  }

  pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    word-break: break-all;
    word-wrap: break-word;
    color: #6a6a6a;
    background-color: transparent;
    border: none;
    border-radius: 0px;
  }

  .ta-editor.border-around {
    border: solid 1px @table-border-color;
  }
}

.ta-310 {
  max-height: 310px;
  overflow-y: auto;
}