html {
  font-size: 16px;
}

html, body, .wrapper, .content-wrapper {
  height: 100%;
}

body {
  font-weight: 300;
}

* {
  transition: background-color 0.4s, border-color 0.4s;
}

a:active, a:focus, a:hover {
  text-decoration: none;
}

[v-cloak] {
  display: none;
}

.content-wrapper {
  padding-left: @sidebar-width;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.header-search-bar {
  position: relative;
  z-index: 1;

  input {
    height: 55px;
    border-left: 0;
    border-top: 0;
    border-right: 0;
  }

  .clear {
    position: absolute;
    right: @grid-gutter-width / 2;
    top: @grid-gutter-width / 2;
    font-size: 22px;
    z-index: 2;
  }
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.gutter {
  margin: @grid-gutter-width;
}

.gutter-bottom {
  margin-bottom: @grid-gutter-width;
}

.gutter-top {
  margin-top: @grid-gutter-width;
}

.gutter-right {
  margin-right: @grid-gutter-width;
}

.gutter-left {
  margin-left: @grid-gutter-width;
}

.half-gutter {
  margin: @grid-gutter-width / 2;
}

.half-gutter-bottom {
  margin-bottom: @grid-gutter-width / 2;
}

.half-gutter-top {
  margin-top: @grid-gutter-width / 2;
}

.half-gutter-right {
  margin-right: @grid-gutter-width / 2;
}

.half-gutter-left {
  margin-left: @grid-gutter-width / 2;
}

.v-align-middle,
.v-align-middle td,
.v-align-middle th {
  vertical-align: middle !important;
}

.row-short {
  margin-bottom: 0;
}

.short {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.wide {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

#app {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;

  main {
    position: relative;
    padding-top: 15px;
    width: 100%;
    flex-grow: 1;
  }
}

/**
 * Hide the duplicate image issue caused by combining
 * material lightbox with dragula.
 */
.gu-mirror {
  display: none;
}