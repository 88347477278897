.sidebar {
  width: @sidebar-width;
  background-color: @sidebar-bg;
  height: 100%;
  border-right: 1px solid @sidebar-border;
  position: fixed;

  .sidebar-brand {
    padding: @grid-gutter-width / 3 @grid-gutter-width / 2;
    border-bottom: 1px solid @sidebar-border;
    text-align: center;
    font-size: 24px;
    height: @header-height;

    a {
      color: @text-color;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .sidebar-avatar {
    display: table;
    width: 100%;
    border-bottom: 1px solid @sidebar-border;
    height: @header-height;

    .sidebar-avatar-content {
      display: block;
      text-align: center;
      margin: 4px 0;
    }
    .sidebar-avatar-buttons {
      display: table;
      width: 100%;
      > * {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        color: @text-color;
      }
    }
  }

  .sidebar-toolbar {
    list-style: none;
    padding: 0;
    display: table;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin: 0;

    .sidebar-toolbar-item {
      display: table-cell;
      text-align: center;
      color: @gray-dark;
      border-right: 1px solid #ddd;

      &:last-child {
        border-right: 0;
      }

      &:hover,
      &.active {
        background-color: @gray-lighter;
      }

      a {
        color: @gray-dark;
        display: block;
        padding: 5px;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .sidebar-menu {
    list-style: none;
    padding: 0;

    li {
      height: 40px;
      font-size: 14px;

      &.sidebar-menu-header {
        padding: 0 @grid-gutter-width / 2;
        background-color: @body-bg;
        border-top: 1px solid @sidebar-border;
        border-bottom: 1px solid @sidebar-border;
        line-height: 40px;
        font-size: 11px;

        .icon {
          font-size: 16px;
          width: 26px;
          display: inline-block;
        }
      }

      &.active {
        background-color: @brand-primary;
        color: #fff;

        a {
          color: #fff;
        }
      }

      a {
        padding: 0 @grid-gutter-width / 2;
        display: table;
        width: 100%;
        height: 100%;
        color: @text-color;

        .icon {
          display: table-cell;
          text-align: left;
          vertical-align: middle;
          width: 26px;
          font-size: 16px;
        }

        span {
          display: table-cell;
          vertical-align: middle;
        }

        &:hover {
          text-decoration: none;
          background-color: @brand-primary-hover;
          color: #fff;
        }
      }
    }
  }
}