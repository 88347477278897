.circle {
  width: 19px;
  height: 19px;
  font-size: 11px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  font-style: normal;
  line-height: 19px;
  display: inline-block;

  &.circle-primary {
    background-color: @brand-primary;
  }
  &.circle-success {
    background-color: @brand-success;
  }
  &.circle-info {
    background-color: @brand-info;
  }
  &.circle-warning {
    background-color: @brand-warning;
  }
  &.circle-danger {
    background-color: @brand-danger;
  }
}