.list {
  width: 100%;
  background-color: #fff;
  border: 1px solid transparent;
  max-height: 100%;
  position: relative;
  white-space: normal;
  display: flex;
  flex-direction: column;

  .list-heading {
    padding: @grid-gutter-width / 3 @grid-gutter-width / 2;
    border-bottom: 1px solid transparent;
    flex: 0 0 auto;

    .list-title {
      margin: 0;
      font-size: 18px;
    }
  }

  .list-cards {
    flex: 1 1 auto;
    position: relative;
    height: 100%;
    min-height: 30px;

    .card {
      border-left: 0;
      border-right: 0;
      border-top: 0;
    }
  }

  .list-body {
    padding: @grid-gutter-width / 3 @grid-gutter-width / 2;

    p:last-child,
    ul:last-child {
      margin-bottom: 0;
    }
  }

  .placeholder {
    color: @gray-dark;
    background-color: #f3f3f3;
    text-align: center;
    position: relative;

    &:before {
      content: "Drop Here";
      font-size: 14px;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
      right: 0;
      position: absolute;
    }
  }

  .list-search {
    border-bottom: 1px solid transparent;
    flex: 0 0 auto;
    position: relative;

    .clear {
      position: absolute;
      font-size: 12px;
      right: 0;
      right: 7px;
      top: 6px;
    }

    input[type="text"],
    input[type="email"],
    input[type="search"] {
      .form-control();
      border: 0;
      font-size: 12px;
      height: 26px;
    }
  }

  .btn {
    border-left: 0;
    border-right: 0;
    flex: 0 0 auto;

    &:last-child {
      border-bottom: 0;
    }
  }

  &.list-default {
    border-color: @list-default-border;

    &.list-default .list-heading {
      border-color: @list-default-border;
      background-color: @list-default-bg;
    }

    &.list-default .list-search {
      border-color: @list-default-border;
    }
  }
}