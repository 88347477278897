.window-info-tab {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: 100000;
  display: block;
  color: white;
  font-size: 12px;
  line-height: 1.8;
  opacity: 0.5;
  transition: opacity 250ms ease-in-out;
  padding: 0 30px;

  &:hover {
    opacity: 1;
  }

  &:after {
    content: "";
    border-top: 20px solid @brand-primary;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    height: 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -50%;
    z-index: -1;
    display: block;
    text-align: center;
  }

  &.dev:after {
    border-top-color: @brand-success;
  }

  &.staging:after {
    border-top-color: @brand-warning;
  }

  &.live:after {
    border-top-color: @brand-danger;
  }

  &.production:after {
    border-top-color: @brand-danger;
  }
}

.header {
  height: @header-height;
  //margin-bottom: @grid-gutter-width / 2;
  display: table;
  width: 100%;
  border-bottom: 1px solid transparent;
  a
  .header-search-bar {
    position: relative;
    z-index: 1;
    height: @header-height;
    display: table-cell;
    vertical-align: middle;

    input {
      border: 0;
      height: @header-height;
      box-shadow: none;
    }

    .clear {
      position: absolute;
      right: @grid-gutter-width / 2;
      top: @grid-gutter-width / 2;
      font-size: 22px;
      z-index: 2;
    }
  }

  .header-toolbar {
    display: table-cell;
    height: @header-height;
    list-style: none;
    padding: 0;
    vertical-align: middle;
    width: 55px;

    &.header-toolbar-right {
      .header-toolbar-item {
        border-left: 1px solid transparent;
      }
    }

    &.header-toolbar-left {
      .header-toolbar-item {
        border-right: 1px solid transparent;
      }
    }

    .header-toolbar-item {
      width: 55px;
      height: 100%;
      display: table;
      text-align: center;

      .close-sidebar {
        font-size: 31px;
        line-height: 38px;
      }

      a, span {
        display: table-cell;
        vertical-align: middle;
        font-size: 18px;
        color: @gray-dark;

        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        background-color: @gray-lighter;
      }
    }
  }

  &.header-default {
    background-color: #fff;
    border-color: #ddd;

    .header-toolbar .header-toolbar-item {
      border-color: #ddd;
    }
  }

  &.header-primary {
    background-color: @brand-primary;
    border-color: @brand-primary-hover;

    .header-toolbar .header-toolbar-item {
      border-color: @brand-primary-hover;
    }
  }

  &.header-success {
    background-color: @brand-success;
    border-color: @brand-success-hover;

    .header-toolbar .header-toolbar-item {
      border-color: @brand-success-hover;
    }
  }

  &.header-info {
    background-color: @brand-info;
    border-color: @brand-info-hover;

    .header-toolbar .header-toolbar-item {
      border-color: @brand-info-hover;
    }
  }

  &.header-warning {
    background-color: @brand-warning;
    border-color: @brand-warning-hover;

    .header-toolbar .header-toolbar-item {
      border-color: @brand-warning-hover;
    }
  }

  &.header-danger {
    background-color: @brand-danger;
    border-color: @brand-danger-hover;

    .header-toolbar .header-toolbar-item {
      border-color: @brand-danger-hover;
    }
  }
}

.page-header {
  margin: 0;
  min-height: @header-height;
  //padding: 20px 15px 21px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  max-height: 81px;
  display: table;
  width: 100%;
  padding: 0;

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  > * {
    display: table-cell;
    vertical-align: middle;
    padding: 0 15px;
  }

  .page-actions > * {
    float: right;
  }
}

.header + .page-header {
  margin-top: -@grid-gutter-width / 2;
}