.preloader {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fff;
  z-index: 999999;
  top: 0;
  left: 0;

  .progress {
    max-width: 600px;
    position: absolute;
    left: 50%;
    margin-top: 230px;
    margin-left: -300px;
  }
}