.avatar {
  width: 60px;
  height: 60px;
  font-size: 30px;
  border-radius: 30px;
  text-align: center;
  color: #fff;
  font-style: normal;
  line-height: 60px;
  display: inline-block;
  overflow: hidden;
  background-color: #fff;

  &.avatar-lg {
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 60px;
    font-size: 60px;
  }

  &.avatar-sm {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    font-size: 15px;
  }

  &.avatar-xs {
    width: 19px;
    height: 19px;
    line-height: 19px;
    border-radius: 15px;
    font-size: 11px;
  }

  &.avatar-a,
  &.avatar-f,
  &.avatar-k,
  &.avatar-p,
  &.avatar-u,
  &.avatar-z {
    background-color: @brand-primary;
  }
  &.avatar-b,
  &.avatar-g,
  &.avatar-l,
  &.avatar-q,
  &.avatar-v {
    background-color: @brand-success;
  }
  &.avatar-c,
  &.avatar-h,
  &.avatar-m,
  &.avatar-r,
  &.avatar-w {
    background-color: @brand-info;
  }
  &.avatar-d,
  &.avatar-i,
  &.avatar-n,
  &.avatar-s,
  &.avatar-x {
    background-color: @brand-warning;
  }
  &.avatar-e,
  &.avatar-j,
  &.avatar-o,
  &.avatar-t,
  &.avatar-y {
    background-color: @brand-danger;
  }
}