///////////////
// variables //
///////////////
@screen-sm: 768px;
@screen-md: 992px;
@screen-lg: 1200px;
@screen-xl: 1400px;

// global
.make-space(@prefix: xs; @value:50;);
.make-space(@prefix: xs; @value:40;);
.make-space(@prefix: xs; @value:30;);
.make-space(@prefix: xs; @value:20;);
.make-space(@prefix: xs; @value:15;);
.make-space(@prefix: xs; @value:10;);
.make-space(@prefix: xs; @value:5;);
.make-space(@prefix: xs; @value:0;);
.xs-mt-a {
  margin-left: auto;
  margin-right: auto;
}

// mobile
@media (min-width: @screen-sm) {
  .make-space(@prefix: sm; @value:100;);
  .make-space(@prefix: sm; @value:80;);
  .make-space(@prefix: sm; @value:60;);
  .make-space(@prefix: sm; @value:50;);
  .make-space(@prefix: sm; @value:40;);
  .make-space(@prefix: sm; @value:30;);
  .make-space(@prefix: sm; @value:20;);
  .make-space(@prefix: sm; @value:15;);
  .make-space(@prefix: sm; @value:10;);
  .make-space(@prefix: sm; @value:5;);
  .make-space(@prefix: sm; @value:0;);
  .sm-mt-a {
    margin-left: auto;
    margin-right: auto;
  }
}

// tablet
@media (min-width: @screen-md) {
  .make-space(@prefix: md; @value:100;);
  .make-space(@prefix: md; @value:80;);
  .make-space(@prefix: md; @value:60;);
  .make-space(@prefix: md; @value:50;);
  .make-space(@prefix: md; @value:40;);
  .make-space(@prefix: md; @value:30;);
  .make-space(@prefix: md; @value:20;);
  .make-space(@prefix: md; @value:15;);
  .make-space(@prefix: md; @value:10;);
  .make-space(@prefix: md; @value:5;);
  .make-space(@prefix: md; @value:0;);
  .md-mt-a {
    margin-left: auto;
    margin-right: auto;
  }
}

// desktop
@media (min-width: @screen-lg) {
  .make-space(@prefix: xl; @value:200;);
  .make-space(@prefix: xl; @value:150;);
  .make-space(@prefix: xl; @value:100;);
  .make-space(@prefix: xl; @value:80;);
  .make-space(@prefix: xl; @value:60;);
  .make-space(@prefix: lg; @value:50;);
  .make-space(@prefix: lg; @value:40;);
  .make-space(@prefix: lg; @value:30;);
  .make-space(@prefix: lg; @value:20;);
  .make-space(@prefix: lg; @value:15;);
  .make-space(@prefix: lg; @value:10;);
  .make-space(@prefix: lg; @value:5;);
  .make-space(@prefix: lg; @value:0;);
  .lg-mt-a {
    margin-left: auto;
    margin-right: auto;
  }
}

// large screens
@media (min-width: @screen-xl) {
  .make-space(@prefix: xl; @value:200;);
  .make-space(@prefix: xl; @value:150;);
  .make-space(@prefix: xl; @value:100;);
  .make-space(@prefix: xl; @value:80;);
  .make-space(@prefix: xl; @value:60;);
  .make-space(@prefix: xl; @value:50;);
  .make-space(@prefix: xl; @value:40;);
  .make-space(@prefix: xl; @value:30;);
  .make-space(@prefix: xl; @value:20;);
  .make-space(@prefix: xl; @value:15;);
  .make-space(@prefix: xl; @value:10;);
  .make-space(@prefix: xl; @value:5;);
  .make-space(@prefix: xl; @value:0;);
  .xl-mt-a {
    margin-left: auto;
    margin-right: auto;
  }
}

// init functions
.make-space(@prefix; @value) {
  .@{prefix}-p-@{value} {
    padding: ~"@{value}px" !important;
  }
  .@{prefix}-pt-@{value} {
    padding-top: ~"@{value}px" !important;
  }
  .@{prefix}-pl-@{value} {
    padding-left: ~"@{value}px" !important;
  }
  .@{prefix}-pr-@{value} {
    padding-right: ~"@{value}px" !important;
  }
  .@{prefix}-pb-@{value} {
    padding-bottom: ~"@{value}px" !important;
  }
  .@{prefix}-m-@{value} {
    margin: ~"@{value}px" !important;
  }
  .@{prefix}-mt-@{value} {
    margin-top: ~"@{value}px" !important;
  }
  .@{prefix}-ml-@{value} {
    margin-left: ~"@{value}px" !important;
  }
  .@{prefix}-mr-@{value} {
    margin-right: ~"@{value}px" !important;
  }
  .@{prefix}-mb-@{value} {
    margin-bottom: ~"@{value}px" !important;
  }
}

.db {
  display: block !important;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw300 {
  font-weight: 300;
}

.spaced {
  letter-spacing: 1px !important;
}

.f12 {
  font-size: 12px !important;
}

.f13 {
  font-size: 13px !important;
}

.f14 {
  font-size: 14px !important;
}

.f16 {
  font-size: 16px !important;
}

.f20 {
  font-size: 20px !important;
}

.f24 {
  font-size: 24px !important;
}

.f30 {
  font-size: 30px !important;

  @media (max-width: 991px) {
    font-size: 25px !important;
  }
}

.f42 {
  font-size: 42px !important;

  @media (max-width: 991px) {
    font-size: 30px !important;
  }
}

.hr {
  border-bottom: 1px solid #d0d0d0
}

.lined {
  line-height: 1.8;
}

.full {
  width: 100% !important;
}

.half {
  width: 50%;
}

.quarter {
  width: 25%;
}

.third {
  width: 33.333%;
}

.tall {
  height: 100%;
}

.italics {
  font-style: italic;
}

.underlined {
  text-decoration: underline;
}

.font-normalise {
  font-style: normal;
  text-decoration: none;
  font-weight: initial;
  color: initial;
  font-size: initial;
  text-transform: none;
}

.naked {
  border: none;
  background: none;
  padding: 0;
}

.table {
  display: table;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.flex-parent {
  display: flex;

  .flex-child {
    flex: 1;
  }
}