.input-group-addon {
  background-color: #fff;
}

.input-group {
  width: 100%;
}

.has-error {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label {
    color: @brand-danger;
  }

  .form-control {
    border-color: @brand-danger;
  }

  label {
    font-weight: 400;
  }
}