.toast {
  right: 15px;
  //position: fixed;
  &.success {
    background: @brand-success;
  }
  &.danger {
    background: @brand-danger;
  }
  &.warning {
    background: @brand-warning;
  }
  &.info {
    background: @brand-info;
  }
  &.primary {
    background: @brand-primary;
  }
}